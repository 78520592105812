import { useState, useEffect, useReducer } from 'react';
import $ from "jquery";
import "./loginFormStyle.css";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { FaUserAlt, FaLock, FaLockOpen } from "react-icons/fa";
import VideoBackground from '../videoBackground/videoBackground';
import AzulLogo from "../../files/logotransp.png";
import GroundLinkLogo from "../../files/GroundLinkLogo.png";
import Lottie from "lottie-react";
import background_1 from "../../animations/background_1.json"
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import axios from 'axios'
import format from 'date-fns/format';
import SessionModal from './sessionModal';
import jwt from 'jwt-decode';
import { BiShow, BiHide } from "react-icons/bi";
import GetUser from '../../Servicios/GetUser';
import ControlUser from '../Modals/ControlUser';




function LoginForm() {
    const [IP, setIP] = useState('');
    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        //console.log(res.data);
        setIP(res.data.IPv4)
    }

    /****************************************************************/




    const formReducer = (state, event) => {
        return {
            ...state,
            [event.name]: event.value
        }
    }
    const [formData, setFormData] = useReducer(formReducer, {});

    const handleChange = event => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }

    const handleSubmit = (e) => {

        getSession();
        e.preventDefault();
        //grabaSesion();
    }


    /**************************************************************/



    const [pass, setPass] = useState(false);
    const [user, setUser] = useState(false);


    function getSession() {
        setPass(false);
        setUser(false);
        $.ajax({
            url: '/Login/procesos_login/login.php',
            data: {
                username: formData.user,
                password: formData.password,
                getSession: "getSession"
            },
            type: 'POST',
        }).then(response => {
            if (parseInt(response) == 2) {
                setPass(true);
            } else if (parseInt(response) == 3) {
                setUser(true);
            } else if (parseInt(response) != 2 && parseInt(response) != 3) {
                const res = JSON.parse(response);
                if (res[0].URL == null) {
                    grabaSesion();
                    /*if ((res[0].Session == "0000-00-00 00:00:00" || res[0].Session == null)) {
                        grabaSesion();
                    } else {
                        setOpenDialog(true);
                    }*/
                } else {
                    return window.location.replace(res[0].URL);
                }

            }
        })


    }


    const userType = (usuario, grupo) => {
        if (usuario == parseInt(process.env.REACT_APP_1)) {
            switch (grupo) {
                case process.env.REACT_APP_1A_GRUPO:
                    return window.location.replace(process.env.REACT_APP_URL_1);
                case process.env.REACT_APP_1B_GRUPO:
                    return window.location.replace(process.env.REACT_APP_URL_1);
                default:
                    return window.location.replace(process.env.REACT_APP_URL_1);
            }
        } else if (usuario == parseInt(process.env.REACT_APP_2)) {
            switch (grupo) {
                case process.env.REACT_APP_2A_GRUPO:
                    return window.location.replace(process.env.REACT_APP_2A_URL);

                case process.env.REACT_APP_2B_GRUPO:
                    return window.location.replace(process.env.REACT_APP_2B_URL);

                case process.env.REACT_APP_2C_GRUPO:
                    return window.location.replace(process.env.REACT_APP_2C_URL);

                case process.env.REACT_APP_2D_GRUPO:
                    return window.location.replace(process.env.REACT_APP_2D_URL);

                case process.env.REACT_APP_2E_GRUPO:
                    return window.location.replace(process.env.REACT_APP_2E_URL);
            }
        }
        else if (usuario == parseInt(process.env.REACT_APP_6)) {
            return window.location.replace(process.env.REACT_APP_URL_6);

        }
        else if (usuario == parseInt(process.env.REACT_APP_36)) {
            switch (grupo) {
                case process.env.REACT_APP_36A_GRUPO:
                    return window.location.replace(process.env.REACT_APP_36A_URL);

                case process.env.REACT_APP_36B_GRUPO:
                    return window.location.replace(process.env.REACT_APP_36B_URL);

                default:
                    return window.location.replace(process.env.REACT_APP_36C_URL);
            }
        } else if (usuario == parseInt(process.env.REACT_APP_11)) {
            if (windowSize.innerWidth >= 800) {
                return window.location.replace(process.env.REACT_APP_URL_11);
            } else {
                return window.location.replace(process.env.REACT_APP_URL_11_MOVIL);
            }
        }
        else {
            let u = parseInt(usuario);
            return window.location.replace(process.env['REACT_APP_URL_' + u]);
        }
    }


    let token = "";

    function grabaSesion() {
        $.ajax({
            url: '/Login/procesos_login/login.php',
            data: {
                username: formData.user,
                password: formData.password,
                startSession: "startSession",
                Session: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
                IP
            },
            type: 'POST',
            success: function (response) {
                token = jwt(response);
                if (parseInt(token.data.TipoUser) == 11 || parseInt(token.data.TipoUser) == 14 || parseInt(token.data.TipoUser) == 15) {
                    sessionStorage.clear();
                    localStorage.clear();

                    sessionStorage.setItem('token', response);

                    userType(parseInt(token.data.TipoUser), token.data.Grupo);
                    sessionStorage.setItem("Base", token.data.Base);
                    sessionStorage.setItem("Fecha", format(new Date(), 'yyyy-MM-dd'));

                    localStorage.setItem('token', response);
                    localStorage.setItem("Base", token.data.Base);
                    localStorage.setItem("Fecha", format(new Date(), 'yyyy-MM-dd'));
                }
                else {
                    sessionStorage.setItem("username", token.data.username);
                    sessionStorage.setItem("Base", token.data.Base);
                    sessionStorage.setItem("Fecha", format(new Date(), 'yyyy-MM-dd'));
                    sessionStorage.setItem("Session", token.data.Session);
                    sessionStorage.setItem("TipoUser", token.data.TipoUser);
                    sessionStorage.setItem("Grupo", token.data.Grupo);
                    userType(parseInt(sessionStorage.getItem("TipoUser")), sessionStorage.getItem("Grupo"));
                }


            }

        });
    }

    /****************************************************************/

    const [windowSize, setWindowSize] = useState(getWindowSize());


    useEffect(() => {
        setWindowSize(getWindowSize());
        getData();
    }, []);



    /*
        const [openDialog, setOpenDialog] = useState(false);
        function openDialogSession() {
            return (
                <SessionModal
                    open={openDialog}
                    onClose={() => setOpenDialog()}
                    formData={formData}
                    ip={IP}
                />
            )
        }
    */
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    function ClassNameContainer() {
        if (windowSize.innerWidth >= 800) {
            return "container_right";
        } else {
            return "container_right_mobile";
        }
    }
    function ClassNameContainerLeft() {
        if (windowSize.innerWidth >= 800) {
            return "container_left";
        } else {
            return "container_left_mobile";
        }
    }

    function ClassNameForm() {
        if (windowSize.innerWidth >= 800) {
            return "container_form";
        } else {
            return "container_form_mobile";
        }
    }

    function ClassNameWidthInput() {
        if (windowSize.innerWidth >= 800) {
            return "350px";
        } else {
            return "250px";
        }
    }

    const [showPass, setShowPass] = useState(false)
    const VisibilityPass = () => {
        if (showPass == true) {
            setShowPass(false);
        } else {
            setShowPass(true);
        }
    }


    /*
     <Link href={"../EnviaEmailConfirmacionOlvidado.php?user=" + formData.user} underline="hover">
                                                {'Forgot Password?'}
                                            </Link>
    */


    const [usuario, setUsuario] = useState();
    const [openUserModal, setOpenUserModal] = useState(false);

    const GetTypeUser = () => {
        GetUser({ username: formData.user }).then((response) => {
            setUsuario(response)
            setOpenUserModal(true)

        })
    }



    return (
        <div className="main_container">
            <div>
                <>
                    {openUserModal ? (
                        <ControlUser data={usuario} open={openUserModal} onClose={() => setOpenUserModal()} />

                    ) : (
                        <></>
                    )}
                </>
            </div>
            {/*
            <div>
                <>
                    {openDialog == true ? (
                        <div>{openDialogSession()}</div>

                    ) : (
                        <></>
                    )}
                </>
            </div>
            */}
            <div className={ClassNameContainerLeft()}>
                <VideoBackground />
            </div>
            <div className={ClassNameContainer()}>
                <>
                    {windowSize.innerWidth >= 800 ? (
                        <div className="background_1">
                            <Lottie
                                className="div_lottie"
                                autoplay={false}
                                loop={false}
                                animationData={background_1}
                            />
                        </div>
                    ) : (
                        <div className="background_mobile"></div>
                    )}
                </>

                <div className="container_logo_main">
                    <div className="container_logo">
                        <img src={GroundLinkLogo} id="logo_groundlink" />
                    </div>
                    
                </div>
                <div className={ClassNameForm()}>
                    <Box sx={{ marginBottom: "10px", marginTop: "25px" }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}>

                            <TextField
                                id="outlined-basic"
                                label="User Name"
                                sx={{ width: ClassNameWidthInput() }}
                                name='user'
                                value={formData.user || ""}
                                onChange={(e) => handleChange(e)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <div id="user_icon"><FaUserAlt id="u_icon" /></div>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined" />
                        </Box>
                    </Box>
                    <Box sx={{ '& > :not(style)': { m: 2 } }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-end',

                        }}>

                            <TextField
                                id="outlined-basic"
                                label="Password"
                                sx={{ width: ClassNameWidthInput() }}
                                name='password'
                                value={formData.password || ""}
                                type={showPass ? "text" : "password"}
                                onChange={(e) => handleChange(e)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <button onClick={VisibilityPass} id="show_pass">{showPass ? <BiHide id="lock_icon" /> : <BiShow id="lock_icon" />}</button>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined" />
                        </Box>
                    </Box>
                    <Stack spacing={2} direction="row" sx={{ paddingBottom: "20px" }}>
                        <Button variant="contained" onClick={handleSubmit}>Login</Button>
                    </Stack>
                    <div className="user_forgot">
                        {
                            formData.user != undefined && formData.user != "" ? (

                                <button style={{ backgroundColor: "transparent", color: "#0277BD", border: "none" }} onClick={GetTypeUser}>
                                    Forgot Password?

                                </button>


                            ) : (
                                <></>
                            )
                        }

                    </div>
                    <div id="error_message">
                        <>
                            {pass == true ? (
                                <p id="passError">
                                    Wrong Pass
                                </p>
                            ) : (
                                <></>
                            )
                            }
                        </>
                        <>
                            {user == true ? (
                                <p id="userError">
                                    User doens´t exist
                                </p>
                            ) : (
                                <></>
                            )
                            }
                        </>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default LoginForm;
